<template>
  <PageWrapper
    :active-page="PAGE_ORGANIZATION_SETTINGS"
    :active-section="SECTION_SETTINGS"
    :breadcrumbs="settingsBreadcrumbs"
    align="left"
    page-name="Company Settings"
    title="Company Settings"
    width="full"
  >
    <ProfileWrapper
      :is-loading-logo="isUploadingLogo"
      :is-loading-splash="isUploadingSplash"
      :is-loading-save-title="isLoadingUpdateOrgName"
      :is-loading-save-details="isLoadingUpdateOrgDescription"
      :logo-image="orgLogo"
      :splash-image="orgSplash"
      :title="orgName"
      :details="orgDescription || 'Add a description for your public profile'"
      can-upload-logo
      can-upload-splash
      can-edit-title
      can-edit-details
      @click:save-title="updateOrgName"
      @click:save-details="updateOrgDescription"
      @click:splash-image="triggerUploadSplash"
      @click:logo-image="triggerUploadLogo"
    >
      <iSpace :height="1" />
      <iRow width="fill" overflow="scroll">
        <iRow wrap="nowrap">
          <iColumn>
            <iHeading>Company Users</iHeading>
          </iColumn>
          <iColumn width="hug">
            <iButton variant="secondary" @click="openModal">
              Add User
            </iButton>
          </iColumn>
        </iRow>
        <iRow :height="600">
          <SmartFeed
            :pinia-store="useCompanyUsersFeedStore"
            default-layout="table"
          />
        </iRow>
      </iRow>

      <iColumn v-if="getOrgData.waitlist_status === 'approved'">
        <iHeading>Organization Profile Page Settings</iHeading>
        <iToggleSwitch
          v-model="orgVisibility.public"
          label="Public"
          @change="handleVisibilityChange('public', orgVisibility.public)"
        />

        <iToggleSwitch
          v-model="orgVisibility.private"
          label="Private"
          @change="handleVisibilityChange('private', orgVisibility.private)"
        />

        <iToggleSwitch
          v-model="orgVisibility.protected"
          label="Protected"
          @change="handleVisibilityChange('protected', orgVisibility.protected)"
        />
        <iHeading>Services Provided</iHeading>
        <iRow v-for="(service, key) in getOrgData.services_offered" :key="key">
          <iCheckbox
            :label="service.service_name"
            :model-value="service.status === 'active'"
            :name="key"
            @update:model-value="(isActive) => handleServiceChange(key, isActive)"
          />
        </iRow>
      </iColumn>
    </ProfileWrapper>
    <iUploader ref="logo-uploader" @upload-file="uploadLogo" />
    <iUploader ref="splash-uploader" @upload-file="uploadSplash" />
    <iModal
      v-if="isAddUserModalVisible"
      :width="600"
      title="Add User"
      @close="closeAndResetAddUserModal"
    >
      <template #body>
        <iColumn gap="large">
          <iTextInput
            v-model="userEmail"
            label="Email address:"
            name="email"
            type="email"
            width="fill"
          />
          <iTextInput
            v-model="userFirstName"
            label="First Name:"
            name="first_name"
            width="fill"
          />
          <iTextInput
            v-model="userLastName"
            label="Last Name:"
            name="last_name"
            width="fill"
          />
          <iSelect
            v-model="userRole"
            :items="userRoleSelectOptions"
            empty-string="Select Role"
            label="Role:"
            text-field="label"
            value-field="value"
            width="fill"
          />
          <iText v-if="addUserErrorMessage" variant="error">
            {{ addUserErrorMessage }}
          </iText>
        </iColumn>
      </template>
      <template #footer>
        <iRow align="right" width="fill">
          <iColumn width="hug">
            <iButton
              :disabled="invalidAddUserForm || loadingCreateUser"
              :is-loading="loadingCreateUser"
              variant="primary"
              @click="addUser"
            >
              Add User
            </iButton>
          </iColumn>
        </iRow>
      </template>
    </iModal>
  </PageWrapper>
</template>
<script>
import PageWrapper from "@/components/PageWrapper";
import SmartFeed from "@/components/smart-feed/SmartFeed";
import {
  PAGE_ORGANIZATION_SETTINGS,
  SECTION_SETTINGS,
} from "@/constants/nav-constants";
import {
  ALLOWED_ADDITIONAL_USER_ROLES,
  ALL_USER_ROLES,
  USER_ROLE_VALUE_OWNER,
} from "@/constants/user-constants";
import { useCollaborationStore } from "@/stores/collaboration-store";
import ProfileWrapper from "@/components/publication/ProfileWrapper";
import { useGeneralStore } from "@/stores/general-store";
import { useOrganizationStore } from "@/stores/organization-store";
import { useCompanyUsersFeedStore } from "@/stores/smart-feed/feed/company-users-feed-store";
import { mapActions, mapState } from "pinia";

export default {
  name: "Organization",
  components: {
    ProfileWrapper,
    PageWrapper,
    SmartFeed,
  },
  data() {
    return {
      SECTION_SETTINGS,
      PAGE_ORGANIZATION_SETTINGS,
      isEditingEmail: false,
      userEmail: "",
      userFirstName: "",
      userLastName: "",
      userRole: {},
      dropdownStates: {},
      orgVisibility: {
        public: false,
        private: false,
        protected: false,
      },
    };
  },
  computed: {
    ...mapState(useOrganizationStore, [
      "orgNameNotEdited",
      "isLoadingUpdateOrgName",
      "orgDescription",
      "getOrgData",
      "loadingCreateUser",
      "isAddUserModalVisible",
      "orgName",
      "addUserErrorMessage",
      "isLoadingUpdateOrgDescription",
      "uploadLogoError",
      "uploadSplashError",
      "isUploadingLogo",
      "isUploadingSplash",
    ]),
    ...mapState(useGeneralStore, [
      "settingsBreadcrumbs",
      "orgLogo",
      "orgSplash",
    ]),
    invalidAddUserForm() {
      return !this.userEmail || !this.userFirstName || !this.userLastName || !this.userRole.value;
    },
    userRoleSelectOptions() {
      return Object.entries(ALLOWED_ADDITIONAL_USER_ROLES).map(userRole => {
        return {
          "label": userRole[1],
          "value": userRole[0],
        };
      });
    },
    ALL_USER_ROLES() {
      return ALL_USER_ROLES;
    },
    USER_ROLE_VALUE_OWNER() {
      return USER_ROLE_VALUE_OWNER;
    },
  },
  created() {
    this.initializeOrgVisibility();
  },
  styleGuide: () => ({
    actionItemPadding: { "size.spacing": "standard" },
  }),
  methods: {
    ...mapActions(useOrganizationStore, [
      "updateOrgName",
      "fetchOrgUsers",
      "createUserInOrg",
      "disableUser",
      "enableUser",
      "changeUserRole",
      "openAddUserModal",
      "closeAddUserModal",
      "updateOrgProfileVisibility",
      "addServiceToOrg",
      "removeServiceFromOrg",
      "updateOrgDescription",
      "uploadLogo",
      "uploadSplash",
    ]),
    ...mapActions(useCollaborationStore, ["openInviteToPublicationModel"]),
    openModal() {
      this.openAddUserModal();
      this.userRole = {};
    },
    addUser() {
      if (this.userEmail && this.userRole !== {}) {
        this.createUserInOrg(this.userEmail, this.userRole.value, this.userFirstName, this.userLastName);
      }
    },
    handleVisibilityChange(setting) {
      const updatedVisibility = {
        public: false,
        private: false,
        protected: false,
      };
      updatedVisibility[setting] = true;
      this.orgVisibility = updatedVisibility;
      this.updateOrgProfileVisibility(setting);
    },
    handleServiceChange(serviceKey, isActive) {
      const service = this.getOrgData.services_offered[serviceKey];
      if (isActive) {
        this.addServiceToOrg(service.service_id);
      } else {
        this.removeServiceFromOrg(service.service_id);
      }
    },
    initializeOrgVisibility() {
      const visibility = this.getOrgData.profile_page_visibility;
      this.orgVisibility = {
        public: visibility === "public",
        private: visibility === "private",
        protected: visibility === "protected",
      };
    },
    closeAndResetAddUserModal() {
      this.userRole = {};
      this.userEmail = "";
      this.userFirstName = "";
      this.userLastName = "";
      this.closeAddUserModal();
    },
    useCompanyUsersFeedStore,
  },
};
</script>
<style scoped>
.dropdown-content {
  padding: v-bind("$getStyles.actionItemPadding");
}

.organization-user-table {
  min-width: 700px;
}
</style>
