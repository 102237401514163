<template>
  <iStyledCard
    :splash-image="entity.publicationSplashImage"
    :badge="entity.status"
    height="hug"
    :width="350"
    :splash-image-min-height="170"
    :splash-image-max-height="170"
    :default-href="`/publication/${entity.publicationId}`"
    :metrics="entity.metrics"
  >
    <template #title>
      <iRow vertical-align="middle" wrap="nowrap">
        <iImageContainer
          object-fit="cover"
          :height="35"
          :width="35"
          shape="circle"
          :src="entity.publicationLogo"
        />
        <iRow
          width="fill"
          align="between"
          vertical-align="middle"
          wrap="nowrap"
        >
          <iSubHeading font-weight="dark">
            {{ entity.name }}
          </iSubHeading>
          <iDropdown :show-dropdown="isDropdownOpen" position="right" @close="closeDropdown">
            <template #trigger>
              <iIcon icon="dots-vertical" @click.prevent="openDropdown" />
            </template>
            <template #overlay>
              <iButton v-if="entity.status !== 'Live'" variant="tertiary" @click.prevent="updateStatus('active')">
                Activate Publication
              </iButton>
              <iButton v-if="entity.status !== 'Draft'" variant="tertiary" @click.prevent="updateStatus('draft')">
                Set As Draft
              </iButton>
              <iButton v-if="entity.status !== 'Offline'" variant="tertiary" @click.prevent="updateStatus('inactive')">
                De-activate Publication
              </iButton>
            </template>
          </iDropdown>
        </iRow>
      </iRow>
    </template>
    <template #subtitle>
      <iLink
        hover-underline
        color="subtle"
        target="_blank"
        :href="subTitleHref"
      >
        {{ subTitle }}
      </iLink>
    </template>
    <template v-if="entity.description" #description>
      <iColumn class="description-wrapper">
        <iLink
          v-if="!entity.hasError"
          hover-underline
          target="_blank"
          :href="descriptionHref"
        >
          {{ entity.description }}
        </iLink>
        <iText v-else variant="error">
          {{ entity.description }}
        </iText>
      </iColumn>
    </template>
  </iStyledCard>
</template>

<script>
import { mapActions } from "pinia";
import { DEFAULT_ORG_LOGO, DEFAULT_SPLASH_IMAGE } from "@/constants/publication-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";
import { usePublicationStore } from "@/stores/publication-store";

export default {
  name: "PublicationCardSmall",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      DEFAULT_ORG_LOGO,
      DEFAULT_SPLASH_IMAGE,
      isDropdownOpen: false,
    };
  },
  computed: {
    subTitle() {
      return this.entity.domain ?? `${window.location.origin}/my-publication/${this.entity.publicationId}`;
    },
    subTitleHref() {
      return `${window.location.origin}/my-publication/${this.entity.publicationId}`;
    },
    descriptionHref() {
      return `${window.location.origin}/my-publication/staging/${this.entity.publicationId}`;
    },
  },
  methods: {
    snakeToTitle,
    ...mapActions(usePublicationStore, ["updatePublicationStatus"]),
    openDropdown() {
      this.isDropdownOpen = true;
    },
    closeDropdown() {
      this.isDropdownOpen = false;
    },
    updateStatus(status) {
      this.updatePublicationStatus(status, this.entity.publicationId);
      this.closeDropdown();
    },
  },
  styleGuide: () => ({
    borderColor: { "color.border": "dark" },
    borderSize: { "size.border": "thin" },
    borderRadius: { "size.borderRadius": "large" },
    paddingTop: { "size.spacing": "large" },
    paddingLeft: { "size.spacing": "large" },
    paddingRight: { "size.spacing": "large" },
    paddingBottom: { "size.spacing": "large" },
    paddingTopSmall: { "size.spacing": "extraSmall" },
    paddingLeftSmall: { "size.spacing": "extraSmall" },
    paddingRightSmall: { "size.spacing": "extraSmall" },
    paddingBottomSmall: { "size.spacing": "extraSmall" },
    menuLinkHorizontalPadding: { "size.spacing": "standard" },
  }),
};

</script>
<style scoped lang="scss">
.publication-card {
  border: v-bind("$getStyles.borderSize") solid v-bind("$getStyles.borderColor");
  border-radius: v-bind("$getStyles.borderRadius");
}

.publication-details {
  padding-top: v-bind("$getStyles.paddingTop");
  padding-left: v-bind("$getStyles.paddingLeft");
  padding-right: v-bind("$getStyles.paddingRight");
  padding-bottom: v-bind("$getStyles.paddingBottom");
}

.description-wrapper {
  padding-top: v-bind("$getStyles.paddingTopSmall");
  padding-bottom: v-bind("$getStyles.paddingBottomSmall");
}

:deep(.button-text-link){
  padding-left: v-bind("$getStyles.menuLinkHorizontalPadding") !important;
  padding-right: v-bind("$getStyles.menuLinkHorizontalPadding") !important;
}
</style>
