// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.i-card[data-v-53ffe41d] {
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/Web/scripts/components/smart-feed/publication/layouts/PublicationCollaboratorCardSmall.vue","webpack://./PublicationCollaboratorCardSmall.vue"],"names":[],"mappings":"AAEA;EACE,iBAAA;ACDF","sourcesContent":["@import \"@bloglovin/vue-component-library/breakpoint-mixin.scss\";\n\n.i-card {\n  overflow: visible;\n}\n",".i-card {\n  overflow: visible;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
