<template>
  <iStyledCard
    :author-name="`${collaborator.name}`"
    width="hug"
    :author-description="`${formattedStatus} &bull; ${roleOrEmail}`"
    :author-avatar="avatar"
  />
</template>

<script>
import { getFormattedStatus } from "@/utils/collaborator-card-utils";
import { DEFAULT_USER_AVATAR } from "@/constants/user-constants";
import { snakeToTitle } from "@bloglovin/vue-component-library";
export default {
  name: "PublicationCollaboratorCardSmall",
  props: {
    entity: {
      type: Object,
      required: true,
    },
  },
  computed: {
    collaborator() {
      return this.entity;
    },
    formattedStatus() {
      return getFormattedStatus(this.collaborator.status);
    },
    avatar() {
      return this.collaborator.avatar || DEFAULT_USER_AVATAR;
    },
    roleOrEmail() {
      if (this.collaborator.role) {
        return snakeToTitle(this.collaborator.role);
      }
      return this.collaborator.email;
    },
  },
  methods: {
    snakeToTitle,
  },
};
</script>

<style scoped lang="scss">
.i-card {
  overflow: visible;
}
</style>
